import { useRef } from "react";
import { motion, useInView } from "framer-motion";

function Footer() {
  const footer = useRef<HTMLDivElement>(null);
  const isInView = useInView(footer, {
    once: true,
    amount: 0.3,
  });

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, delay: custom * 0.1 },
    }),
  };

  return (
    <div className="relative w-full">
      <div
        ref={footer}
        className="relative mx-auto w-[91%] max-w-[1608px] h-full overflow-hidden px-4 md:px-[60px] py-8 md:py-[30px]"
      >
        <div className="grid grid-cols-2 md:grid-cols-12 gap-8">
          {/* Logo and Social Text Section */}
          <motion.div
            className="col-span-2 md:col-span-3 mb-8 md:mb-0"
            variants={variants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            custom={0}
          >
            <div className="flex items-center gap-2 mb-6">
              <img
                src="./landing/logo.svg"
                alt="Colibri"
                className="w-8 h-8 md:w-10 md:h-10"
              />
              <h2 className="text-xl md:text-2xl font-Gloock">COLIBRI</h2>
            </div>
            <p className="text-gray-600 mb-4 text-sm md:text-base">
              Follow us on social media to stay updated with the latest tips.
            </p>
            <a
              href="mailto:colibri@gmail.com"
              className="text-gray-600 hover:text-gray-800 text-sm md:text-base"
            >
              colibri@gmail.com
            </a>
          </motion.div>

          {/* Links Container for Mobile */}
          <div className="col-span-2 md:hidden">
            <div className="grid grid-cols-2 gap-8">
              {/* Company Links - Mobile */}
              <motion.div
                variants={variants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                custom={1}
              >
                <h3 className="text-base font-medium mb-3">Company</h3>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      À propos
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      Portfolio
                    </a>
                  </li>
                </ul>
              </motion.div>

              {/* Legal Links - Mobile */}
              <motion.div
                variants={variants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                custom={2}
              >
                <h3 className="text-base font-medium mb-3">Legal</h3>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                      Case Studies
                    </a>
                  </li>
                </ul>
              </motion.div>
            </div>

            {/* Social Links - Mobile */}
            <motion.div
              className="mt-8"
              variants={variants}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              custom={3}
            >
              <h3 className="text-base font-medium mb-3">Social</h3>
              <div className="flex flex-wrap gap-4">
                <a
                  href="#"
                  className="text-gray-600 hover:text-gray-800 text-sm"
                >
                  Facebook
                </a>
                <a
                  href="#"
                  className="text-gray-600 hover:text-gray-800 text-sm"
                >
                  Twitter
                </a>
                <a
                  href="#"
                  className="text-gray-600 hover:text-gray-800 text-sm"
                >
                  LinkedIn
                </a>
                <a
                  href="#"
                  className="text-gray-600 hover:text-gray-800 text-sm"
                >
                  Instagram
                </a>
              </div>
            </motion.div>
          </div>

          {/* Desktop Links */}
          <motion.div
            className="hidden md:block md:col-span-2 md:col-start-6"
            variants={variants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            custom={1}
          >
            <h3 className="text-lg font-medium mb-4">Company</h3>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Home
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  À propos
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Services
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Portfolio
                </a>
              </li>
            </ul>
          </motion.div>

          <motion.div
            className="hidden md:block md:col-span-2"
            variants={variants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            custom={2}
          >
            <h3 className="text-lg font-medium mb-4">Legal</h3>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  FAQs
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Case Studies
                </a>
              </li>
            </ul>
          </motion.div>

          <motion.div
            className="hidden md:block md:col-span-2"
            variants={variants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            custom={3}
          >
            <h3 className="text-lg font-medium mb-4">Social</h3>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Facebook
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Twitter
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Instagram
                </a>
              </li>
            </ul>
          </motion.div>
        </div>

        {/* Copyright - Both Mobile and Desktop */}
        <motion.div
          className="mt-8 pt-8 border-t border-gray-200 text-center md:text-left"
          variants={variants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          custom={4}
        >
          <p className="text-sm text-gray-600">
            © {new Date().getFullYear()} Colibri. All rights reserved.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Footer;
