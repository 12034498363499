// import { useCursor } from "@/context/CursorContext";
import { motion } from "framer-motion";
import { useState } from "react";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // const { setCursorType } = useCursor();
  return (
    <div className="w-full relative z-[100] h-[200px] flex items-center">
      <div className="flex container justify-between items-center  mx-auto w-[91%]">
        <img className="h-full max-h-[60px] " src="./landing/logo.svg" alt="" />

        <motion.div
          className="h-auto cursor-pointer flex flex-col items-center gap-y-[7px] p-2"
          onHoverStart={() => {
            setIsHovered(true);
            //  setCursorType("menu")
          }}
          onHoverEnd={() => {
            setIsHovered(false);
            //  setCursorType("play")
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {/* Top line - only visible on hover */}
          <motion.div
            className="h-[1px] bg-[#343434] rounded-full"
            initial={{ width: "50px", opacity: 0, y: 10 }}
            animate={{
              width: "50px",
              opacity: isOpen ? 0 : isHovered ? 1 : 0,
              y: isOpen ? 25 : isHovered ? 0 : 10,
            }}
            transition={{
              duration: 0.3,
              opacity: { duration: isOpen ? 0.1 : 0.3 },
              y: { duration: 0.3 },
            }}
          />

          {/* Middle line */}
          <motion.div
            className="h-[1px] bg-[#343434] rounded-full"
            initial={{ width: "50px" }}
            animate={{
              width: "50px",
              rotate: isOpen ? 40 : 0,
            }}
            transition={{ duration: 0.3 }}
          />

          {/* Bottom line */}
          <motion.div
            className="h-[1px] bg-[#343434] rounded-full"
            initial={{ width: "50px" }}
            animate={{
              width: "50px",
              rotate: isOpen ? -40 : 0,
              y: isOpen ? -9 : 0,
            }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      </div>
    </div>
  );
}

export default Header;
