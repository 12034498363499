import { motion } from "framer-motion";

const categoties = [
  {
    name: "ALL",
    slug: "all",
  },
  {
    name: "Social Media",
    slug: "social_media",
  },
  {
    name: "Strategie Digitale",
    slug: "strategie_digitale",
  },
  {
    name: "SEO",
    slug: "seo",
  },
  {
    name: "Web Design",
    slug: "web_design",
  },
  {
    name: "Audiovisuelle",
    slug: "audiovisuelle",
  },
];

const blogs = [
  {
    id: 1,
    date: "22.05.04",
    title:
      "De l'UGC spontané à l'UGC rémunéré : quand le contenu 'authentique' se professionnalise",
    category: "Social Media",
    image: "./landing/blogs/marketing.jpg",
  },
  {
    id: 2,
    date: "22.05.04",
    title:
      "De l'UGC spontané à l'UGC rémunéré : quand le contenu 'authentique' se professionnalise",
    category: "Social Media",
    image: "./landing/blogs/marketing.jpg",
  },
  {
    id: 3,
    date: "22.05.04",
    title:
      "De l'UGC spontané à l'UGC rémunéré : quand le contenu 'authentique' se professionnalise",
    category: "Social Media",
    image: "./landing/blogs/marketing.jpg",
  },
];

function Blogs() {
  return (
    <div className="relative w-full h-auto py-[100px]">
      <div className="mx-auto w-[91%] !h-full">
        <motion.div
          className="w-[91%] mx-auto text-center flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <div className="lg:max-w-[60%] max-w-[90%]">
            <h1 className="font-bold text-[30px] lg:text-[50px]">Blogs</h1>
            <p className="font-Dmsans text-[16px] lg:text-[20px]">
              We Create digital marketing campaign that go beyond the screen and
              deliver real results, real-time, personalized adverts in the palm
              of your hand
            </p>
          </div>
        </motion.div>

        <div className="mx-auto">
          <div
            className="w-full overflow-hidden overflow-y-auto relative p-[20px]"
            style={{
              columns: "400px",
              perspectiveOrigin: "center center",
              maxHeight: "calc(100vh - 250px)",
              overflowY: "auto",
            }}
          ></div>
          <div className="">
            <div className="grid md:grid-cols-[repeat(auto-fit,minmax(400px,1fr))] grid-cols-[repeat(auto-fit,minmax(100%,1fr))] gap-[15px]">
              {blogs.map((blog) => (
                <div
                  key={blog.id}
                  className="flex flex-col gap-y-[20px] md:gap-y-[30px] min-w-full md:min-w-[400px] transition-all duration-500 group mb-[50px]"
                >
                  <div className="min-h-[200px] h-[200px] bg-[#E8214A] w-full overflow-hidden rounded-[20px]">
                    <img
                      src={blog.image}
                      alt={blog.title}
                      className="w-full h-full object-cover group-hover:scale-[1.1] transition-all duration-500 "
                    />
                  </div>
                  <div className="text-[16px] font-Dmsans">{blog.date}</div>
                  <div className="text-[16px] md:text-[20px] font-Dmsans font-semibold leading-6">
                    {blog.title}
                  </div>
                  <div className="text-[16px] font-Dmsans">{blog.category}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
