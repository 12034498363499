import { motion, useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const gradient0 = "bg-[#FF305F]";
const gradient1 = "bg-[#D96A1A]";
const gradient2 = "bg-[#0AB9F1]";
const gradient3 = "bg-[#41E76C]";

interface Testimonial {
  name: string;
  company: string;
  text: string;
  image: string;
  color: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Michael",
    company: "MDS Manufacturing",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna.",
    image: "./landing/blogs/marketing.jpg",
    color: gradient0,
  },
  {
    name: "Diane",
    company: "ABC Rentals",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.",
    image: "./landing/blogs/marketing.jpg",
    color: gradient1,
  },
  {
    name: "Allison",
    company: "Grand Party Rental",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
    image: "./landing/blogs/marketing.jpg",
    color: gradient2,
  },
  {
    name: "Sarah",
    company: "XYZ Corp",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
    image: "./landing/blogs/marketing.jpg",
    color: gradient0,
  },
  {
    name: "John",
    company: "Tech Solutions",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
    image: "./landing/blogs/marketing.jpg",
    color: gradient3,
  },
  //     quote:
  //       "Their innovative strategies helped us tap into new market segments we hadn't considered before. A true game-changer for our business.",
  //     author: "Sophie Taylor",
  //     role: "Growth Manager",
  //     color: gradient0,
  //     imageUrl: "./landing/temoignage/metropol.png",
  //   },
];

const TestimonialCarousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateSlidesPerView = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth >= 1280) setSlidesPerView(4);
        else if (containerWidth >= 1024) setSlidesPerView(3);
        else if (containerWidth >= 768) setSlidesPerView(2);
        else setSlidesPerView(1);
      }
    };

    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => window.removeEventListener("resize", updateSlidesPerView);
  }, []);

  const maxSlides = Math.max(0, testimonials.length - slidesPerView);

  const nextSlide = () => {
    setCurrentSlide((prev) => Math.min(prev + 1, maxSlides));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => Math.max(prev - 1, 0));
  };

  const goToSlide = (index: number) => {
    setCurrentSlide(Math.min(index, maxSlides));
  };

  const getTotalPages = () => Math.ceil(testimonials.length / slidesPerView);
  const isVisible = useInView(containerRef, { once: true, amount: 0.3 });
  return (
    <div className="w-full mx-auto" ref={containerRef}>
      <div className="relative">
        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${
                currentSlide * (100 / slidesPerView)
              }%)`,
            }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex-shrink-0 px-2"
                style={{ width: `${100 / slidesPerView}%` }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  animate={{
                    opacity: isVisible ? 1 : 0,
                    y: isVisible ? 1 : 100,
                  }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  className={`${testimonial.color} rounded-[20px] text-white relative overflow-hidden rotateZ-[50deg]`}
                >
                  <div className="flex gap-x-[0px] absolute bottom-[10%] right-[5%] opacity-10">
                    <svg
                      width="85"
                      height="133"
                      className=""
                      viewBox="0 0 18 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 14.5H8C8 9.7 14.3333 6.83333 17.5 6L14.5 0.5C13.6667 0.833333 10.8 2.2 6 5C1.2 7.8 0 11.8333 0 13.5V33H17.5V14.5Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      width="85"
                      height="133"
                      viewBox="0 0 18 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 14.5H8C8 9.7 14.3333 6.83333 17.5 6L14.5 0.5C13.6667 0.833333 10.8 2.2 6 5C1.2 7.8 0 11.8333 0 13.5V33H17.5V14.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="relative h-full text-white font-Dmsans p-[40px]">
                    <div className="relative h-full w-full flex flex-col gap-y-[30px]">
                      <p className="font-Dmsans text-[16px] lg:text-[20px]">
                        {testimonial.text}
                      </p>
                      <div className="flex items-center gap-4">
                        {testimonial.image && (
                          <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            className="w-12 h-12 rounded-full border-[2px] border-white "
                          />
                        )}
                        <div>
                          <p className="font-bold">{testimonial.name}</p>
                          <p className=" italic text-[16px]">
                            {testimonial.company}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Buttons */}
        {currentSlide > 0 && (
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 -ml-4 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
        )}
        {currentSlide < maxSlides && (
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 -mr-4 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        )}

        {/* Dot Indicators */}
        <div className="flex justify-center mt-6 gap-2">
          {[...Array(getTotalPages())].map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-2 h-2 rounded-full transition-colors ${
                Math.floor(currentSlide / slidesPerView) === index
                  ? "bg-[#FF305F]"
                  : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

function Testimony() {
  return (
    <div className="relative w-full h-auto flex">
      <div
        className={`w-[91%] mx-auto !h-full flex flex-col gap-[30px] items-center relative rounded-l-[80px] py-[100px] justify-between`}
      >
        <motion.div
          className="w-[91%] mx-auto text-center flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <div className="lg:max-w-[60%] max-w-[80%]">
            <h1 className="font-bold text-[30px] lg:text-[50px]">Temoignage</h1>
            <p className="font-Dmsans text-[16px] lg:text-[20px]">
              We Create digital marketing campaign that go beyond the screen and
              deliver real results, real-time, personalized adverts in the palm
              of your hand
            </p>
          </div>
        </motion.div>
        <TestimonialCarousel />
      </div>
    </div>
  );
}

export default Testimony;
