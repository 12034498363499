import { useRef, useState } from "react";
import { motion, useInView } from "framer-motion";

interface Team {
  id: number;
  name: string;
  role: string;
  avatar: string;
}

const temoignagedata: Team[] = [
  {
    id: 1,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  },
  {
    id: 2,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  },
  {
    id: 3,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  },
  {
    id: 4,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  },
  {
    id: 5,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  },
  {
    id: 6,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  },
  {
    id: 7,
    name: "Alice Johnson",
    role: "CEO",
    avatar: "./landing/blogs/jhon.png",
  }
];

function Team() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const sectionTemoignage = useRef<HTMLDivElement>(null);
  const isInView = useInView(sectionTemoignage, { once: true, amount: 0.3 });
  // const Theteam = temoignagedata.slice(
  //   currentIndex * 4,
  //   (currentIndex + 1) * 4
  // );
  return (
    <div className="relative w-full ">
      {/* <TemoignageHeader
        title="Meet Our Teams"
        desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
        length={temoignagedata.length}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      /> */}

<motion.div
          className="w-[91%] mx-auto text-center flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <div className="lg:max-w-[60%] max-w-[80%]">
            <h1 className="font-bold text-[30px] lg:text-[50px]">
            Meet Our Teams
            </h1>
            <p className="font-Dmsans text-[16px] lg:text-[20px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
        </motion.div>
        <div
          ref={sectionTemoignage}
          className="relative mx-auto w-[91%] h-full overflow-hidden"
        >
          <div className="w-full flex py-[60px] flex-wrap gap-[30px] ">
            {temoignagedata.map((p, index) => (
              <motion.div
                className=" flex flex-1"
                key={p.id}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -50 }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.15,
                }}
              >
                <div className="flex-1">
                    <div className="flex items-center flex-col group gap-y-[30px]">
                        <div className="relative w-[200px] md:w-[280px] aspect-square">
                            <div className="absolute w-full h-full bg-[#FF335D] group-hover:translate-x-[-20px] group-hover:translate-y-[-20px] transition-all duration-500 rounded-full"></div>
                            <img className="z-[90] relative border-4 border-[#FDF9F6] rounded-full group-hover:translate-x-[10px] group-hover:translate-y-[10px] transition-all duration-500 " src="./landing/blogs/jhon.png" alt="" />
                        </div>
                        <div className="w-full text-center">
                            <p className="text-[25px] font-Dmsans">{p.name}</p>
                            <p className="text-[20px] font-Dmsans">{p.role}</p>
                        </div>
                    </div>
                </div>
              </motion.div>
            ))}
          </div>
      </div>
    </div>
  );
}

export default Team;
