import { motion, useInView } from "framer-motion";
import { useRef } from "react";

function Contact() {
  const Contactref = useRef<HTMLDivElement>(null);
  const isVisible = useInView(Contactref, { once: true, amount: 0.3 });
  const inputVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, delay: custom * 0.1 },
    }),
  };
  const inputStyles =
    "w-full p-4 border border-gray-300 bg-transparent focus:outline-none focus:border-gray-500 rounded-full";

  return (
    <div
      ref={Contactref}
      className="relative w-full py-[100px]"
    >
      <div className="relative mx-auto w-[91%] max-w-[1608px] h-full">
        <div className="flex items-center flex-col md:flex-row">
          <div className="w-full md:w-3/4 flex flex-col md:px-[60px] py-[30px]">
            <motion.div
              className="w-[91%] mx-auto text-center flex justify-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <div className="lg:max-w-[60%] max-w-[80%]">
                <h1 className="font-bold text-[30px] lg:text-[50px]">
                  Contact Us
                </h1>
              </div>
            </motion.div>
            <div>
              <div className="mt-12 space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <motion.div
                    variants={inputVariants}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                    custom={1}
                  >
                    <input
                      type="text"
                      placeholder="Full Name"
                      className={inputStyles}
                    />
                  </motion.div>

                  <motion.div
                    variants={inputVariants}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                    custom={2}
                  >
                    <input
                      type="tel"
                      placeholder="Phone"
                      className={inputStyles}
                    />
                  </motion.div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <motion.div
                    variants={inputVariants}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                    custom={3}
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      className={inputStyles}
                    />
                  </motion.div>

                  <motion.div
                    variants={inputVariants}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                    custom={4}
                  >
                    <input
                      type="text"
                      placeholder="Company"
                      className={inputStyles}
                    />
                  </motion.div>
                </div>

                <motion.div
                  variants={inputVariants}
                  initial="hidden"
                  animate={isVisible ? "visible" : "hidden"}
                  custom={5}
                >
                  <input
                    type="text"
                    placeholder="Subject"
                    className={inputStyles}
                  />
                </motion.div>

                <motion.div
                  variants={inputVariants}
                  initial="hidden"
                  animate={isVisible ? "visible" : "hidden"}
                  custom={6}
                >
                  <textarea
                    placeholder="Message"
                    rows={6}
                    className={`${inputStyles} !rounded-[30px]`}
                  />
                </motion.div>

                <motion.div
                  variants={inputVariants}
                  initial="hidden"
                  animate={isVisible ? "visible" : "hidden"}
                  custom={7}
                >
                  <button className="w-full py-4 rounded-full bg-[#E94D35] text-white hover:bg-[#d43d2a] transition-colors">
                    ENVOYER
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 ">
            <div className="h-full !rounder-[30px] overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2707.478526673085!2d-1.5534444841983042!3d47.21659237916163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4805eeb7485e4345%3A0x6c7c3efb42dc876b!2sLe%20Broc%C3%A9liande!5e0!3m2!1sen!2sfr!4v1645438226599!5m2!1sen!2sfr"
                className="w-full min-h-[500px]"
                style={{ border: 0 }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
