import { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface PortfolioItem {
  id: number;
  title: string;
  description: string;
  media: string;
  type: "image" | "video";
}

const portfolioData: PortfolioItem[] = [
  {
    id: 1,
    title: "MBanque",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/MBanque.mp4",
    type: "image",
  },
  {
    id: 2,
    title: "ENAKL",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/enakl.mp4",
    type: "image",
  },
  {
    id: 3,
    title: "SAM GROUP",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/sam.mp4",
    type: "image",
  },
  {
    id: 4,
    title: "Xperienciah",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/Kaan.mp4",
    type: "image",
  },
  {
    id: 5,
    title: "Mundiapolis",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/Mundiapolis.mp4",
    type: "image",
  },
  {
    id: 6,
    title: "NAPS",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/naps.mp4",
    type: "image",
  },
  {
    id: 7,
    title: "Mundiapolis",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/spot.mp4",
    type: "image",
  },
  {
    id: 8,
    title: "Wafacash",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/Wafacash.m4v",
    type: "image",
  },
  {
    id: 9,
    title: "Humans",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    media: "./landing/portfolio/Humans.mp4",
    type: "image",
  },
];

function Portfolio() {
  const [selectedItem, setSelectedItem] = useState<PortfolioItem>(
    portfolioData[0]
  );
  const [direction, setDirection] = useState(1);
  const portfolioHeader = useRef<HTMLDivElement>(null);
  const videoRefs = useRef<{ [key: number]: HTMLVideoElement | null }>({});
  const touchStartX = useRef<number>(0);

  // Handle video cleanup when switching items
  const handleItemSelect = (item: PortfolioItem) => {
    const currentVideo = videoRefs.current[selectedItem.id];
    if (currentVideo) {
      currentVideo.pause();
      currentVideo.currentTime = 0;
    }

    setDirection(item.id > selectedItem.id ? 1 : -1);
    setSelectedItem(item);
  };

  const handleNext = () => {
    const currentIndex = portfolioData.findIndex(
      (item) => item.id === selectedItem.id
    );
    if (currentIndex < portfolioData.length - 1) {
      handleItemSelect(portfolioData[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    const currentIndex = portfolioData.findIndex(
      (item) => item.id === selectedItem.id
    );
    if (currentIndex > 0) {
      handleItemSelect(portfolioData[currentIndex - 1]);
    }
  };

  // Touch handlers for swipe navigation
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const touchEndX = e.changedTouches[0].clientX;
    const diffX = touchEndX - touchStartX.current;

    if (Math.abs(diffX) > 50) {
      // minimum swipe distance
      if (diffX > 0) {
        handlePrevious();
      } else {
        handleNext();
      }
    }
  };

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      rotate: 20,
    }),
    center: {
      x: 0,
      opacity: 1,
      rotate: -6,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        mass: 1,
        opacity: { duration: 0.2 },
      },
    },
    exit: (direction: number) => ({
      x: direction > 0 ? -1000 : 1000,
      opacity: 0,
      rotate: 20,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 30,
      },
    }),
  };

  const contentVariants = {
    enter: () => ({
      y: 30,
      opacity: 0,
    }),
    center: {
      y: 0,
      opacity: 1,
      transition: {
        y: {
          type: "tween",
          duration: 0.6,
          ease: "easeOut",
        },
        opacity: {
          duration: 0.5,
          ease: "easeOut",
        },
      },
    },
    exit: {
      y: -30,
      opacity: 0,
      transition: {
        y: {
          type: "tween",
          duration: 0.6,
          ease: "easeIn",
        },
        opacity: {
          duration: 0.3,
        },
      },
    },
  };

  return (
    <div className="relative w-full h-auto py-[100px]">
      {/* Header Section */}
      <div
        ref={portfolioHeader}
        className="relative w-full overflow-hidden mb-[100px]"
      >
        <motion.div
          className="w-[91%] mx-auto text-center flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <div className="lg:max-w-[60%] max-w-[80%]">
            <h1 className="font-bold text-[30px] lg:text-[50px]">
              Realisation
            </h1>
            <p className="font-Dmsans text-[16px] lg:text-[20px]">
              We Create digital marketing campaign that go beyond the screen and
              deliver real results, real-time, personalized adverts in the palm
              of your hand
            </p>
          </div>
        </motion.div>
      </div>

      <div className="relative mx-auto w-[91%] h-full">
        <div className="w-full flex h-[60vh]">
          {/* Left side - Work Display */}
          <div className="w-1/4 items-center hidden md:flex">
            <div className="w-full">
              {portfolioData.map((item, index) => (
                <motion.div
                  key={item.id}
                  className={`p-[10px] border-[#343434] text-center transition-all duration-300 cursor-pointer hover:text-[#E8214A]
                    ${index < portfolioData.length - 1 ? "border-b-[1px]" : ""}
                    ${
                      selectedItem.id === item.id
                        ? "py-[30px] text-[30px] text-[#E8214A]"
                        : "text-[20px] text-[#343434]"
                    }`}
                  onClick={() => handleItemSelect(item)}
                >
                  <motion.h2
                    className="font-semibold font-Dmsans"
                    initial={false}
                    animate={{
                      opacity: selectedItem.id === item.id ? 1 : 0.6,
                    }}
                  >
                    {item.title}
                  </motion.h2>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Right side - Project List */}
          <div
            className="md:w-3/4 w-full relative overflow-hidden flex"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <div className="absolute h-full w-full flex flex-col">
              <div className="relative w-full h-full">
                {/* Mobile Navigation Controls */}
                <div className="md:hidden absolute top-1/2 left-0 right-0 z-50 flex justify-between items-center px-4 transform -translate-y-1/2 pointer-events-none">
                  <motion.button
                    className="w-12 h-12 rounded-full bg-white/80 flex items-center justify-center shadow-lg pointer-events-auto"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handlePrevious}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    disabled={selectedItem.id === portfolioData[0].id}
                  >
                    <ChevronLeft className="w-6 h-6 text-gray-800" />
                  </motion.button>

                  <motion.button
                    className="w-12 h-12 rounded-full bg-white/80 flex items-center justify-center shadow-lg pointer-events-auto"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleNext}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    disabled={
                      selectedItem.id ===
                      portfolioData[portfolioData.length - 1].id
                    }
                  >
                    <ChevronRight className="w-6 h-6 text-gray-800" />
                  </motion.button>
                </div>

                {/* Mobile Current Item Indicator */}
                {/* <div className="md:hidden absolute bottom-20 left-0 right-0 flex justify-center gap-2 z-50">
                  {portfolioData.map((item, index) => (
                    <motion.div
                      key={item.id}
                      className={`h-2 rounded-full transition-all duration-300 ${
                        selectedItem.id === item.id ? 'w-8 bg-[#E8214A]' : 'w-2 bg-gray-300'
                      }`}
                      whileHover={{ scale: 1.2 }}
                      onClick={() => handleItemSelect(item)}
                    />
                  ))}
                </div> */}

                <div className="relative w-full h-full flex items-center justify-center overflow-hidden">
                  <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                      key={selectedItem.id}
                      custom={direction}
                      variants={slideVariants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      className="absolute md:max-w-[60%] max-w-[70%] flex items-center justify-center bg-[#D96A1A] rounded-[15px] md:rounded-[30px]"
                      onAnimationStart={() => {
                        const video = videoRefs.current[selectedItem.id];
                        if (video) {
                          video.pause();
                          video.currentTime = 0;
                        }
                      }}
                    >
                      <div className="flex translate-x-[25px] translate-y-[-25px] rounded-[15px] md:rounded-[30px] overflow-hidden">
                        <video
                          ref={(el) =>
                            (videoRefs.current[selectedItem.id] = el)
                          }
                          className="w-full h-full max-h-[410px] object-contain"
                          autoPlay={false}
                          loop
                          controls
                          playsInline
                          onPlay={() => {
                            // Optionally handle play event
                            console.log(`Playing video ${selectedItem.id}`);
                          }}
                          onPause={() => {
                            // Optionally handle pause event
                            console.log(`Paused video ${selectedItem.id}`);
                          }}
                        >
                          <source src={selectedItem.media} type="video/mp4" />
                        </video>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                </div>

                {/* Mobile Title Display */}
                <div className="md:hidden absolute top-0 left-0 right-0 text-center p-4">
                  <motion.h2
                    className="text-2xl font-semibold text-[#E8214A]"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    key={selectedItem.title}
                  >
                    {selectedItem.title}
                  </motion.h2>
                </div>

                <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    key={`content-${selectedItem.id}`}
                    variants={contentVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    custom={direction}
                    className="px-[60px] md:py-[30px] absolute bottom-0 w-full"
                  >
                    <h3 className="text-xl font-semibold">
                      {selectedItem.title}
                    </h3>
                    <p className="text-gray-600 mt-2">
                      {selectedItem.description}
                    </p>
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
