import { useState, useRef } from "react";
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react";

const HeroSection = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Video container variants
  const videoContainerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Layer animation variants
  const layerVariants = {
    initial: () => ({
      bottom: 0,
      right: 0,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: "easeIn",
        delay: 0, // Reverse delay order for returning
      },
    }),
    animate: (index: number) => ({
      opacity: 1,
      bottom: `${index * 10}px`,
      right: `${index * 10}px`,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.2 + index * 0.2,
      },
    }),
  };
  const layerVariantsSmall = {
    initial: () => ({
      bottom: 0,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: "easeIn",
        delay: 0, // Reverse delay order for returning
      },
    }),
    animate: (index: number) => ({
      opacity: 1,
      bottom: `${index * 5}%`,
      with: `${100 - index * 5}%`,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.2 + index * 0.1,
      },
    }),
  };

  // Video container animation variants
  const videoWrapperVariants = {
    initial: {
      right: 0,
      width: "100%",
      transition: {
        duration: 0.4,
        ease: "easeIn",
        delay: 0, // First to return
      },
    },
    animate: {
      bottom: "48px",
      right: "48px",
      width: "130%",
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.5,
      },
    },
  };

  const videoWrapperVariantsSmall = {
    initial: {
      bottom: 0,
      width: "100%",
      transition: {
        duration: 0.4,
        ease: "easeIn",
        delay: 0, // First to return
      },
    },
    animate: {
      bottom: "28%",
      width: "91%",
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.5,
      },
    },
  };

  // Handle video state changes
  const handleVideoStateChange = (playing: boolean) => {
    setIsVideoPlaying(playing);
  };

  return (
    <div className="relative w-full overflow-hidden md:mb-[20px] mb-[200px]">
      <div className="w-[91%] mx-auto h-full">
        <div className="grid lg:grid-cols-2 gap-[100px] sm:gap-8 items-center w-full md:justify-items-stretch justify-items-center  min-h-[calc(100vh-200px)]">
          {/* Text Content */}
          <motion.div
            className="z-10 space-y-6 w-full md:max-w-[65%] py-[20px]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
              Your Partner <br />
              in Digital <br />
              Growth
            </h1>

            <p className="text-gray-600 text-lg md:text-[20px] font-Dmsans max-w-md">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum
            </p>

            <motion.button
              className="flex items-center gap-2 bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 transition-colors group"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Started
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </motion.button>
          </motion.div>

          {/* Video Section Small device*/}
          <motion.div
            className="relative w-full h-full items-center justify-center lg:hidden flex"
            initial="hidden"
            animate="visible"
            variants={videoContainerVariants}
          >
            <div className="relative flex items-center justify-center h-[200px] md:h-[300px] xl:h-[400px] w-full">
              {/* Decorative layers */}
              <motion.div
                custom={1}
                variants={layerVariantsSmall}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                className={`absolute hidden sm:flex h-full bg-[#FF335D] rounded-[2rem] ${
                  isVideoPlaying ? "w-[100%]" : "w-full"
                }`}
              />
              <motion.div
                custom={2}
                variants={layerVariantsSmall}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                className={`absolute hidden sm:flex  h-full bg-[#0AB9F1] rounded-[2rem] ${
                  isVideoPlaying ? "w-[97%]" : "w-full"
                }`}
              />
              <motion.div
                custom={3}
                variants={layerVariantsSmall}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                className={`absolute hidden sm:flex h-full bg-[#D96A1A] rounded-[2rem] ${
                  isVideoPlaying ? "w-[94%]" : "w-full"
                }`}
              />

              {/* Video container */}
              <motion.div
                className={`rounded-[1rem] sm:rounded-[2rem] overflow-hidden  bg-gray-200 absolute z-20 ${
                  isVideoPlaying ? "!w-full sm:!w-[94%]" : ""
                }`}
                variants={videoWrapperVariantsSmall}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
              >
                <video
                  ref={videoRef}
                  className="w-full h-full object-cover"
                  autoPlay
                  loop
                  controls
                  playsInline
                  onPlay={() => handleVideoStateChange(true)}
                  onPause={() => handleVideoStateChange(false)}
                  onLoadedData={() => {
                    if (videoRef.current?.played) {
                      handleVideoStateChange(true);
                    }
                  }}
                >
                  <source src="./landing/hero/hero.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </motion.div>
            </div>
          </motion.div>

          {/* Video Section */}
          <motion.div
            className="relative min-w-[60%] h-full items-center justify-center hidden lg:flex"
            initial="hidden"
            animate="visible"
            variants={videoContainerVariants}
          >
            <div className="relative flex items-center h-[300px] xl:h-[400px] w-full">
              <motion.div
                custom={1}
                variants={layerVariants}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                className="absolute w-full h-full bg-[#FF335D] rounded-[2rem]"
              />
              <motion.div
                custom={2}
                variants={layerVariants}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                className="absolute w-full h-full bg-[#0AB9F1] rounded-[2rem]"
              />
              <motion.div
                custom={3}
                variants={layerVariants}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                className="absolute w-full h-full bg-[#D96A1A] rounded-[2rem]"
              />

              <motion.div
                className="rounded-[2rem] overflow-hidden bg-gray-200 absolute z-20"
                variants={videoWrapperVariants}
                initial="initial"
                animate={isVideoPlaying ? "animate" : "initial"}
                // style={{
                //   transform: `translateY(${scrollY * 0.2}px)`
                // }}
              >
                <video
                  ref={videoRef}
                  className="w-full h-full object-cover"
                  autoPlay
                  loop
                  controls
                  playsInline
                  onPlay={() => handleVideoStateChange(true)}
                  onPause={() => handleVideoStateChange(false)}
                  onLoadedData={() => {
                    if (videoRef.current?.played) {
                      handleVideoStateChange(true);
                    }
                  }}
                >
                  <source src="./landing/hero/hero.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
