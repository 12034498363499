import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroSection from './components/Hero';
import Portfolio from './components/Portfolio';
import ServicesSection from './components/Services';
import Team from './components/Team';
import Testimony from './components/Testimony';


const Landingv3 = () => {
  return (
    <div className="w-full h-screen relative" >
      <img className="scale-[3] z-[0] fixed" src="/landingv3/gradient.svg" alt="" />
      <Header />
      <div className="h-[calc(100vh-200px)] relative">
        <div 
          className={`absolute w-full h-full transition-opacity duration-300`}
        >
          <HeroSection/>
          <ServicesSection/>
          <Testimony/>
          <Blogs/>
          <Portfolio/>
          <Team/>
          <Contact/>
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default Landingv3;